import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router"; // 引入 router
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/styles/global.css";
import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";

const app = createApp(App);
const head = createHead();

app.use(head);
app.use(router); // 使用 router
app.use(ElementPlus);
app.use(CkeditorPlugin);
app.mount("#app");
