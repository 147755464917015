import { createRouter, createWebHistory } from "vue-router";
import { useHead } from "@vueuse/head";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
        meta: { title: "生成文章 - 写得好", description: "生成文章" },
    },
    {
        path: "/history",
        name: "history",
        component: () => import("@/views/History.vue"),
        meta: { title: "历史文章 - 写得好", description: "历史文章" },
    },
    {
        path: "/sample",
        name: "sampleView",
        component: () => import("@/views/SampleView.vue"),
        meta: { title: "文章精选 - 写得好", description: "文章精选" },
    },
    {
        path: "/sample/:id",
        name: "sampleDetail",
        component: () => import("@/views/SampleDetail.vue"),
        // 这里的 meta 由页面中根据文章详情动态设置
    },
    {
        path: "/auth",
        name: "auth",
        component: () => import("@/views/AuthPage.vue"),
        meta: { title: "登录 - 写得好", description: "登录" },
    },
    {
        path: "/edit",
        name: "ArticleEdit",
        component: () => import("@/views/ArticleEdit.vue"),
        meta: { title: "编辑文章 - 写得好", description: "编辑文章" },
    },
    {
        path: "/admin/seo",
        name: "SeoList",
        component: () => import("@/views/admin/SeoList.vue"),
        meta: { title: "seo管理 - 写得好", description: "seo管理" },
    },
    {
        path: "/admin/seo/add",
        component: () => import("@/views/admin/SeoEdit.vue"),
        meta: { title: "添加seo - 写得好", description: "添加seo" },
    },
    {
        path: "/admin/seo/edit/:id",
        component: () => import("@/views/admin/SeoEdit.vue"),
        meta: { title: "编辑seo - 写得好", description: "编辑seo" },
    },
    {
        path: "/admin/users",
        name: "AdminUserList",
        component: () => import("@/views/admin/UserList.vue"),
        meta: { title: "用户管理 - 写得好", description: "用户管理" },
    },
    {
        path: "/admin/article",
        name: "AdminArticleList",
        component: () => import("@/views/admin/ArticleList.vue"),
        meta: { title: "文章管理 - 写得好", description: "文章管理" },
    },
    {
        path: "/admin/article/edit",
        name: "AdminArticleEdit",
        component: () => import("@/views/admin/ArticleEdit.vue"),
        meta: { title: "查看编辑文章 - 写得好", description: "查看编辑文章" },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    useHead({
        title: to.meta.title || "写得好",
        meta: [
            {
                name: "description",
                content: to.meta.description || "",
            },
        ],
    });
    next();
});

export default router;
